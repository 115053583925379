<!--
 * @Description:
 * @Version: 2.0
 * @Author: 林子健
 * @Date: 2023-09-04 11:20:17
 * @LastEditors: 林子健
 * @LastEditTime: 2023-10-12 09:33:36
-->
<!--市场动态列表-->
<template>
  <PageContainer class="container">
    <div class="search-box">
      <el-form ref="form" :model="form" label-width="100px">
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item v-if="policyCategoryData && policyCategoryData[0]" label="报告类型"  class="articleCategory">

              <div class="bigbox">
                <div class="flexC" >
                  <div class="lable-box">
                    <el-checkbox class="allBtn" :label="policyCategoryData&&policyCategoryData[0].name" size="small" @change="ycbgchange" v-model="ycbg" :indeterminate="isIndeterminate1">
                    </el-checkbox>
                  </div>
                  <el-checkbox-group v-model="ycbgList" size="small" @change="ycbghandleChange">
                    <el-checkbox v-for="item in policyCategoryData[0].children" :key="item.id"
                      :label="item.id">
                      {{item.name }}
                      <!-- <span v-if="isClear(ycbgList, item.id)"><i class="el-icon-close"></i> </span>-->
                      </el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item v-if="policyCategoryData && policyCategoryData[1]"  class="articleCategory">
              <div class="bigbox">
                <div class="flexC" >
                  <div class="lable-box">
                    <el-checkbox class="allBtn" :label="policyCategoryData&&policyCategoryData[1].name" size="small" @change="sfbgchange" v-model="sfbg" :indeterminate="isIndeterminate2">
                    </el-checkbox>
                  </div>
                  <el-checkbox-group v-model="sfbgList" size="small" @change="qyhandleChange">
                    <el-checkbox v-for="item in policyCategoryData[1].children" :key="item.id"
                      :label="item.id">{{
                      item.name }}
                      <!-- <span v-if="isClear(sfbgList, item.id)"><i class="el-icon-close"></i> </span>-->
                      </el-checkbox>
                  </el-checkbox-group>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="特色标签" class="contentCategory">
              <el-checkbox-group v-model="form.contentCategory">
                <el-checkbox
                  v-for="(item, index) in contentCategoryList"
                  :key="index"
                  :label="item.dictValue"
                >{{ item.dictLabel }}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="发布时间">
              <div
                :class="
                  `tag-item ${
                    form.publishTimeFlag == null ? 'tag-item-click' : ''
                  }`
                "
                @click="selectTime(null)"
              >
                全部
              </div>
              <div
                :class="
                  `tag-item ${
                    form.publishTimeFlag == 'month' ? 'tag-item-click' : ''
                  }`
                "
                @click="selectTime('month')"
              >
                近一个月
              </div>
              <div
                :class="
                  `tag-item ${
                    form.publishTimeFlag == 'halfYear' ? 'tag-item-click' : ''
                  }`
                "
                @click="selectTime('halfYear')"
              >
                近半年
              </div>
              <div
                :class="
                  `tag-item ${
                    form.publishTimeFlag == 'year' ? 'tag-item-click' : ''
                  }`
                "
                @click="selectTime('year')"
              >
                近一年
              </div>
              <el-date-picker
                v-model="form.date"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                size="small"
                value-format="yyyy-MM-dd"
                style="width:200px"
                @change="selectChange"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="10">
            <el-form-item label="关键字">
              <el-input
                v-model="form.title"
                placeholder="请输入关键字"
                size="small"
              />
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item>
              <div class="search-btn-box">
                <el-button
                  size="small"
                  type="primary"
                  @click="getListData"
                >查询</el-button>
                <el-button size="small" @click="resize">重置</el-button>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="info-box">
      <div class="desc" @click="desc">
        时间排序
        <i v-if="form.order === 'desc'" class="el-icon-caret-bottom" />
        <i v-else class="el-icon-caret-top" />
      </div>
      <div class="info-scroll-box">
        <massageItem
          v-for="(item, index) in cardList"
          :key="index"
          :data="item"
          log-records-column1="国际化"
          log-records-column2="研究报告"
          log-records-table-name="tt_international_report"
          link-url="/international/researchReport/details/"
        />
        <!-- <massageItem v-for="i in 5" :key="i"></massageItem> -->
      </div>
    </div>
    <div class="pages">
      <el-pagination
        v-if="pageShow"
        :current-page="pageData.pages.pageNo"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageData.pages.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageData.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <!-- <template slot="right">
      <information
        class="information"
        icon-type="5"
        :data="HotList"
        log-records-column1="国际化"
        log-records-column2="研究报告"
        log-records-table-name="tt_international_report"
        link-url="/international/researchReport/details/"
      />
    </template> -->
  </PageContainer>
</template>
<script>
import massageItem from '../common/massageItem'
import advertisement from '../common/advertisement'
import information from '../common/information'
import { selectDictDataByType } from '@/api/dict.js'
import {
  getInternationalReport,
  getinternationalReportHotData
} from '@/api/data_new.js'
import { buryPoint } from '@/api/common'
import { getUserInfo, openSy } from '@/utils/auth.js'
import { ttDemension } from "@/api/dict.js";
export default {
  components: {
    massageItem,
    advertisement,
    information
  },
  data() {
    return {
      form: {
        articleCategory: [],
        contentCategory: [],
        date: [],
        publishTimeFlag: null,
        beginTime: null,
        endTime: null,
        order: 'desc'
      },
      cardList: [],
      HotList: [],
      articleCategoryList: [],
      contentCategoryList: [],
      pageData: {
        pages: {
          pageNo: 1,
          pageSize: 10
        },
        total: 0,
        currentPage: 0
      },
      pageShow: true,
      formLog: {
        eventName: '开通卡片点击',
        eventType: '2',
        columnName: '公告',
        userId: ''
      },
      policyCategoryData: [],
      ycbgList: [],
      sfbgList: [],
      ycbg: false,
      sfbg: false,
      isIndeterminate1:false,
      isIndeterminate2: false,
      policyList1: [],
      policyList2: []
    }
  },
  created() {
    this.form = { ...this.form, ...this.$route.query }
    this.form.articleCategory = []
    if (this.$route.query.articleCategory) {
      this.form.articleCategory = [this.$route.query.articleCategory]
    }
    this.getDict('international_report_type', 'articleCategoryList')
    this.getDict('international_report_tag', 'contentCategoryList')
    this.getListData()
    this.getHotData()
  },
  mounted() {
    this.$nextTick(() => {
      ttDemension({code: "internationalType"}).then((res) => {
        if (res.ok) {
          this.policyCategoryData = res.data
          this.policyList1 = this.policyCategoryData[0].children.map((item) => {
            return item.id
          })
          this.policyList2 = this.policyCategoryData[1].children.map((item) => {
            return item.id
          })
        }
      });
    })
  },
  watch: {
    // ycbgList: {
    //   deep: true,
    //   immediate: true,
    //   handler(newVal, oldVal) {
    //     this.ycbg = newVal.length <= 0 ? true : false
    //   },
    // },
    // sfbgList: {
    //   deep: true,
    //   immediate: true,
    //   handler(newVal, oldVal) {
    //     this.sfbg = newVal.length <= 0 ? true : false
    //   }
    // },
  },
  methods: {
    ycbgchange(val) {
      // this.ycbg = true
      this.ycbgList = val ? this.policyList1 : []
      this.isIndeterminate1 = false;
      this.form.articleCategory=this.ycbgList
      // this.getListData()
    },
    ycbghandleChange(val) {
      let checkedCount = val.length;
      this.ycbg = checkedCount === this.policyList1.length;
      this.isIndeterminate1 = checkedCount > 0 && checkedCount < this.policyList1.length;
      this.ycbgList = val
      this.form.articleCategory
        = (this.ycbgList.concat(this.sfbgList))
      // this.getListData()
    },
    sfbgchange(val) {
      this.sfbgList = val ? this.policyList2 : []
      this.isIndeterminate2 = false;
      this.form.articleCategory=this.sfbgList
      // this.getListData()
    },
    qyhandleChange(val) {
     let checkedCount = val.length;
      this.sfbg = checkedCount === this.policyList2.length;
      this.isIndeterminate2 = checkedCount > 0 && checkedCount < this.policyList2.length;
      this.sfbgList = val
      this.form.articleCategory = (this.ycbgList.concat(this.sfbgList))
      // this.getListData()
    },
    sdbghandleChange() {
      this.formLog.userId = getUserInfo().userId
      buryPoint(this.formLog)
      this.getListData()
    },
    desc() {
      this.form.order = this.form.order === 'desc' ? 'asc' : 'desc'
      this.getListData()
    },
    // 获取HotList数据
    getHotData() {
      getinternationalReportHotData().then((res) => {
        if (res.ok) {
          this.HotList = res.data
        }
      })
    },
    /**
     * @description: 查询重置
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-08 11:28:38
     * @LastEditTime: Do not edit
     */
    resize() {
      (this.form = {
        articleCategory: [],
        contentCategory: [],
        date: [],
        publishTimeFlag: null,
        beginTime: null,
        endTime: null,
        order: 'desc'
      })
      this.ycbgList = []
      this.sfbgList = []
      this.ycbg= false,
      this.sfbg= false,
      this.isIndeterminate1= false,
      this.isIndeterminate2= false,
      this.getListData()
    },
    /**
     * @description: x选择时间范围
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-08 16:17:24
     * @LastEditTime: Do not edit
     */

    selectTime(type) {
      this.form.publishTimeFlag = type
      this.form.date = []
      this.form.beginTime = null
      this.form.endTime = null
    },
    /**
     * @description: 时间选择框
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-08 16:33:14
     * @LastEditTime: Do not edit
     */

    selectChange(e) {
      this.form.publishTimeFlag = ''
      this.form.beginTime = e[0]
      this.form.endTime = e[1]
    },
    // 获取字典数据
    getDict(tyep, resDataName) {
      selectDictDataByType({ dictType: tyep }).then((res) => {
        if (res.ok) {
          this[resDataName] = res.data
        }
      })
    },
    // 获取列表数据
    getListData() {
      this.cardList = []
      const parma = JSON.parse(JSON.stringify(this.form))
      parma.contentCategory =
        parma.contentCategory.length > 0
          ? parma.contentCategory.join(',')
          : null
      parma.articleCategory =
        parma.articleCategory.length > 0
          ? parma.articleCategory.join(',')
          : null
      getInternationalReport(
        `?pageNo=${this.pageData.pages.pageNo}&pageSize=${this.pageData.pages.pageSize} `,
        parma
      ).then((res) => {
        if (res.ok) {
          this.cardList = res.data.list
          this.cardList.forEach((el) => {
            if (el.contentCategory != null) {
              el.contentCategory = el.contentCategory.split(',')
            }
            if (el.belongAreas != null) {
              el.belongAreas = el.belongAreas.split(',')
            }
          })
          this.pageData.total = res.data.count
        }
      })
    },
    /**
     * @description: 页数切换
     * @param {*} val
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-08 11:01:53
     * @LastEditTime: Do not edit
     */

    handleSizeChange(val) {
      this.pageData.pages.pageSize = val
      this.getListData()
    },
    /**
     * @description: 页码切换
     * @param {*} val
     * @return {*}
     * @author: 林子健
     * @TODO:
     * @Date: 2023-09-08 11:02:01
     * @LastEditTime: Do not edit
     */

    handleCurrentChange(val) {
      const menu = this.$route.meta.acName
      if (getUserInfo()?.roleKey === 'trialrole' && val > 3 && !getUserInfo()?.forumName?.includes(menu)) {
        // 则不允许翻页，弹窗提示并埋点
        openSy()
        this.insertBuryPoint()
        this.pageShow = false
        this.$nextTick(() => {
          this.pageShow = true
        })
        return
      } else {
        this.pageData.pages.pageNo = val
        this.getListData()
      }
    }
  }
}
</script>
<style lang="scss">
$BassZoom: 1;
.main-html {
  font-size: calc(24px * #{$BassZoom}) !important;
}
@media screen and (min-width: 1920px) {
  .main-html {
    font-size: calc(33.6px * #{$BassZoom}) !important;
  }
}
@media screen and (max-width: 1920px) {
  .main-html {
    font-size: calc(24px * #{$BassZoom}) !important;
  }
}
@media screen and (max-width: 1366px) {
  .main-html {
    font-size: 16px !important;
  }
}
</style>
<style lang="scss" scoped>
$BassZoom: 1;

.container {
  ::v-deep .column-main {
    background-color: #fff;
  }
}
.search-box {
  width: calc(100% - 0.5rem * #{$BassZoom});
  margin: calc(0.25rem * #{$BassZoom});
  // height: 8.666rem;
  padding: calc(1rem * #{$BassZoom});
  background-color: #f0f4f9;
  .el-form /deep/ .el-form-item__label {
    text-align: start;
  }
  .articleCategory {
    // /deep/ .el-form-item__content {
    //   display: flex;
    //   align-items: center;
    // }
    // .el-checkbox-group {
    //   display: flex;
    //   margin-top: 0.2rem;
    // }
    .allBtn {
      /deep/.el-checkbox__label {
        color: #0d57bc;
        font-size: 16px;
        font-weight: 800;
      }
    }
    /deep/ .el-form-item__content {
      display: flex;
      align-items: center;
    }
    .el-checkbox-group {
      display: flex;
      align-items: center;
      height: 40px;
      // margin-top: 0.5rem;
    }
  }
  .contentCategory {
    /deep/ .el-form-item__content {
      display: flex;
      align-items: center;
    }
    .el-checkbox-group {
      display: flex;
      align-items: center;
      height: 40px;
      // margin-top: 0.5rem;
    }
  }

  .el-checkbox {
    display: flex;
    align-items: center;
  }
  .checkbox {
    display: inline-block;
    width: 3.7rem;
    height: 1.333rem;
    background: url(~@/assets/img/overview/ycbg.png) no-repeat;
    background-size: 100% 100%;
  }
  /deep/ .el-form-item {
    margin-bottom: 0.24rem;
    .tag-item {
      margin-right: 0.5rem;
      background-color: #fff;
      border: 1px solid #dcdfe6;
      display: inline-block;
      height: 32px;
      padding: 0 10px;
      line-height: 30px;
      font-size: 12px;
      color: #333333;
      border-width: 1px;
      border-style: solid;
      border-radius: 4px;
      box-sizing: border-box;
      white-space: nowrap;
      cursor: pointer;
    }
    .tag-item-click {
      background-color: rgba(13, 87, 188, 0.08);
      border: 1px solid #0d57bc;
      color: #0d57bc;
    }
  }
  .search-btn-box {
    float: right;
  }
}
.info-box::-webkit-scrollbar {
  display: none;
}
.info-box {
  width: 100%;
  min-height: 21.134rem;
  padding: 1rem;
  // overflow-y: scroll;
  .desc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(75px * #{$BassZoom});
    height: calc(26px * #{$BassZoom});
    margin-bottom: 16px;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
  }
  .info-scroll-box {
    width: 100%;
  }
}
.pages {
  margin: 1rem;
}

.information {
  width: 100%;
  // width: calc(16.25rem * #{$BassZoom});
  height: 24rem;
  margin-bottom: calc(0.4rem * #{$BassZoom});
}
.advertisement {
  width: 100%;
  // width: calc(16.25rem * #{$BassZoom});
  height: 9.416rem;
}

@media screen and (max-width: 1366px) {
  .search-box {
    // height: 12.66rem !important;
  }
}
 .bigbox {
    // background: #ffffff;
    padding: 0 0px;

    /deep/.el-select .el-input__inner::placeholder {
      color: rgb(51, 51, 51);
    }

    .flexC {
      // margin-bottom: 20px;
      display: flex;
      align-items: center;
      label {
        display: inline-block;
        // width: 100px;
      }
    }

    .checkTitle {
      width: 60px;
      margin-right: 16px;
      display: inline-block;
      color: rgb(153, 153, 153);
    }

    ::v-deep {
      .el-input__inner {
        border-radius: 4px;
      }

      .el-checkbox {
        margin-right: 30px;

        .el-checkbox-button__inner {
          padding: 7px 13px;
          min-width: 58px;
          color: #333;
          font-size: 14px;
          font-weight: normal;
          border-radius: 22px !important;
          border: 0;

          span {
            margin-top: 1px;
            margin-right: -4px;
          }
        }
      }

      .el-checkbox .el-checkbox-button__inner:hover {
        color: #3f76ea;
      }

      .is-checked .el-checkbox-button__inner {
        background: #3f76ea !important;
        color: #FFF !important;
        border-color: #fff !important;
      }

      .el-form--inline .el-form-item__content {
        line-height: normal;
      }
    }
  }
</style>
