<template>
  <div v-if="flag" class="login-mask">
    <div class="login-box">
      <div class="close-btn" @click="close('form')">
        <i class="el-icon-close" />
<!--                      关闭-->
      </div>

<!--      <div-->
<!--        style="height: auto;padding: 22px 10px;color:#333333;font-size: 14px;margin-top: 85px;font-weight: bold;text-align: justify;margin-left: 175px;width: 570px"-->
<!--      >-->
<!--        <div style="margin-bottom: 10px">新版企业情报全新升级，五大板块内容，一键掌握核心企业产业布局和战略动态。内容全面扩展，交互界面全新改版并增加企业对比功能。</div>-->
<!--        <div style="margin-bottom: 10px"><span style="color: red">企业信息模块体量增加,交互更加便捷。</span>一期企业信息覆盖 46 家整车企业、65 家零部件企业包括多维度信息;并推出一键对比分析功能,支持一键生成多家企业对比分析报告。</div>-->
<!--        <div style="margin-bottom: 10px"><span style="color: red">分析报告模块内容提升,质量更加优化。</span>聚焦整车和零部件企业战略动态、产业布局、新产品发布、技术动态等信息,1300+份原创报告和第三方报告。</div>-->
<!--        <div style="margin-bottom: 10px"><span style="color: red">全新增加产业布局地图,掌握区域格局。</span>一键了解整车和核心零部件产业区域竞争格局。</div>-->
<!--        <div style="margin-bottom: 10px"><span style="color: red">全新增加月度分析数据,监控月度市场。</span>每月定期更新企业产销数据,包括企业榜单、车型榜单等多维度信息。</div>-->
<!--        <div style="margin-bottom: 10px"><span style="color: red">优化企业财报数据内容,掌握经营动态。</span>企业财报覆盖 110 多家企业的季度财报、年度财报,及时且准确追踪了解企业经营情况。</div>-->
<!--        <div style="color: red;text-align: center">信息网现有客户直接免费试用1周,其他用户可点击<span style="color: #0D57BC;cursor: pointer" @click="toHref('/sqSy?flag='+1)">申请试用</span></div>-->

<!--      </div>-->
                <img src="@/assets/img/gqfjtz2024.png" style="width: 800px;height: auto"/>
      <div>
<!--        <el-button style="float: right;margin-right: 20px;margin-top: 10px;" type="primary" @click="close('form')">-->
<!--          知道了-->
<!--        </el-button>-->
      </div>

    </div>
  </div>
</template>
<script>
import { login } from '@/api/login.js'
import {
  setUserInfo,
  getUserInfo,
  restUserInfo,
  setUserState,
  restUserState,
  getUserState,
  getSecrecy,
  setSecrecy,
  restSecrecy
} from '@/utils/auth.js'
import store from '@/store'
import router from '@/router'

export default {
  name: 'Vlogin',
  data() {
    var validateUsername = (rule, value, callback) => {
      if (this.swicthName == '手机号登录') {
        if (value === '') {
          callback(new Error('请输入帐号'))
        } else {
          callback()
        }
      }
    }

    var validatePassword = (rule, value, callback) => {
      if (this.swicthName == '手机号登录') {
        if (value === '') {
          callback(new Error('请输入密码'))
        } else {
          callback()
        }
      }
    }
    var validateUserPhone = (rule, value, callback) => {
      if (this.swicthName == '账号密码登录') {
        if (value === '') {
          callback(new Error('请输入手机号码'))
        } else if (!/^[1][3,4,5,7,8,9][0-9]{9}$/.test(value)) {
          callback(new Error('手机号码格式不正确'))
        } else {
          callback()
        }
      }
    }
    var validateYzm = (rule, value, callback) => {
      if (this.swicthName == '账号密码登录') {
        if (value === '') {
          callback(new Error('请输入验证码'))
        } else {
          callback()
        }
      }
    }
    // username: "jyc",
    //   password: "123456",
    return {
      form: {
        username: '',
        password: '',
        phone: '',
        yzm: '',
        remember: false
      },
      rules: {
        username: [{ validator: validateUsername, trigger: 'blur' }],
        password: [{ validator: validatePassword, trigger: 'blur' }],
        phone: [{ validator: validateUserPhone, trigger: 'blur' }],
        yzm: [{ validator: validateYzm, trigger: 'blur' }]
      },
      flag: false,
      loading: false,
      swicthName: '手机号登录',
      menuName: ''
    }
  },
  created() {
    if (getUserInfo()) {
      if (getUserInfo().roleKey == 'paidrole' || getUserInfo().roleKey == 'trialrole') {
        this.menuName = getUserInfo().menuName
      }
    }

    // this.form.remember = userInfo?userInfo.remember:false;
    this.form.remember = getUserState()
    if (getUserState()) {
      this.form.username = getSecrecy().username
      this.form.password = getSecrecy().password
    }
  },
  methods: {
    toHref(url) {
      const newUrl = router.resolve(url)

      window.open(newUrl.href, '_blank')
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          const form = {}
          if (this.swicthName == '手机号登录') {
            form.username = this.form.username
            form.password = this.form.password
            form.rememberMe = this.form.remember
          } else {
            form.phone = this.form.phone
            form.yzm = this.form.yzm
          }
          form.browserId = store.state.common.browserId
          login(form).then((res) => {
            if (res.ok) {
              this.$message({
                showClose: true,
                message: '登录成功',
                type: 'success'
              })
              sessionStorage.removeItem('version')
              form.remember = this.form.remember
              // let data = Object.assign(form, res.data);
              if (form.remember) {
                setUserInfo(res.data)
                setUserState(true)
                setSecrecy(form)
              } else {
                restUserInfo(res.data)
                restUserState(true)
                restSecrecy(form)
              }
              this.flag = false
              window.location.reload()
            }
            this.loading = false
          })
        }
      })
    },
    close() {
      this.flag = false
      this.$emit('close')
    },
    show() {
      this.flag = true
    },
    loginSwitch() {
      this.swicthName =
                this.swicthName == '手机号登录' ? '账号密码登录' : '手机号登录'
      this.form.yzm = ''
      this.$refs.form.resetFields()
    }
  }
}
</script>
<style lang="scss" scoped>
.login-mask {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
}

.login-box {
    position: relative;
    width: 800px;
  //height: 500px;
    background: #ffffff;
    //padding-bottom: 20px;
    border-radius: 8px;
    margin: auto;
  //background: url("../../../assets/images/kgfl.jpg");

    .close-btn {
        position: absolute;
        top: 15px;
        right: 15px;
        font-size: 16px;
        cursor: pointer;
        color: white;
    }

    img {
        width: 150px;

        //margin: 20px;
    }

    .login-form {
        width: 360px;
        margin: 0 auto;

        .yzm-btn {
            position: absolute;
            right: 9px;
        }
    }
}
</style>
