<template>
  <div class="login-mask" v-if="flag">
    <div class="login-box">
      <div class="close-btn" @click="close('form')">
        <i class="el-icon-close"></i>
      </div>
      <img src="@/assets/img/login-logo3.png" alt=""/>
      <div flex="1">
        <!--      <img style="" src="@/assets/img/login-logo.png" alt=""/>-->
        <center>
          <el-menu style="width: 270px; margin: 12px 0;"
                   :default-active="activeIndex"
                   class="el-menu-demo"
                   mode="horizontal"
                   @select="handleSelect"
                   active-text-color="#0d57bc"
                   text-color="black"
          >
            <el-menu-item index="1">账号登录</el-menu-item>
            <el-menu-item index="3" disabled>|</el-menu-item>
            <el-menu-item index="2">手机号登录</el-menu-item>
          </el-menu>
        </center>
      </div>
      <template v-if="activeIndex == '1'">
        <el-form key="loginFormKey" ref="form" :model="form" :rules="rules" class="login-form">

          <el-form-item prop="username">
            <el-input
                v-model="form.username"
                prefix-icon="el-icon-user"
                placeholder="请输入帐号"
                autocomplete='on'
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
                v-model="form.password"
                prefix-icon="el-icon-lock"
                placeholder="请输入密码"
                type="password"
                autocomplete='on'
                show-password
            ></el-input>
          </el-form-item>
          <el-form-item prop="remember">
            <div flex="sb">
              <el-checkbox label="记住密码" v-model="form.remember"></el-checkbox>
              <!-- <a href="mpletent.exe">下载插件</a> -->
              <div style="color: #0f0f0f" @click="toHref('/sqSy')">没有账号？
                <a href="#">
                  申请试用
                </a>
              </div>
            </div>

          </el-form-item>

          <el-form-item class="text-center">
            <el-button
                type="primary"
                @click="onSubmit('form')"
                style="width: 100%"
                :loading="loading"
            >登录
            </el-button
            >
            <!--          <el-button type="text" @click="loginSwitch()">{{-->
            <!--            swicthName-->
            <!--          }}</el-button>-->
          </el-form-item>
<!--          <div style="color: #0f0f0f" @click="toHref('/sqSy')">没有账号？-->
<!--            <a href="#">-->
<!--              申请试用-->
<!--            </a>-->
<!--          </div>-->
        </el-form>

      </template>
      <template v-else>
        <el-form key="phoneKey" ref="form2" :model="form2" :rules="rules2" class="login-form">

        <el-form-item prop="phone">
          <el-input
              v-model="form2.phone"
              prefix-icon="el-icon-phone"
              placeholder="请输入手机号"
              autocomplete="off"

          ></el-input>
        </el-form-item>
        <el-form-item prop="yzm">
          <el-input
              style="width: 220px;"
              v-model="form2.yzm"
              prefix-icon="el-icon-key"
              placeholder="请输入验证码"
              autocomplete="off"

          >
          </el-input>
          <el-button style="height: 40px; width: 136px;margin-left: 4px;" type="primary" @click="getCode()"
                     :class="{'disabled-style':getCodeBtnDisable}" :disabled="getCodeBtnDisable">{{ codeBtnWord }}
          </el-button>
        </el-form-item>
          <el-form-item prop="remember">
            <div flex="sb" style="float: right">

              <div style="color: #0f0f0f;" @click="toHref('/sqSy')">没有账号？
                <a href="#">
                  申请试用
                </a>
              </div>
            </div>

          </el-form-item>
          <el-form-item class="text-center">
            <el-button
                type="primary"
                @click="onSubmit('form2')"
                style="width: 100%"
                :loading="loading"
            >登录
            </el-button
            >
            <!--          <el-button type="text" @click="loginSwitch()">{{-->
            <!--            swicthName-->
            <!--          }}</el-button>-->
          </el-form-item>

        </el-form>
      </template>

    </div>
  </div>
</template>
<script>
import {login, sendPhoneMessageByLogin} from "@/api/login.js";
import {
  setUserInfo,
  getUserInfo,
  restUserInfo,
  setUserState,
  restUserState,
  getUserState,
  getSecrecy,
  setSecrecy,
  restSecrecy,
} from "@/utils/auth.js";
import store from "@/store";
import router from "@/router";

export default {
  name: "Vlogin",
  data() {
    var validateUsername = (rule, value, callback) => {
      if (this.activeIndex == '1') {
        if (value === "") {
          callback(new Error("请输入帐号"));
        } else {
          callback();
        }
      }
    };

    var validatePassword = (rule, value, callback) => {
      if (this.activeIndex == '1') {
        if (value === "") {
          callback(new Error("请输入密码"));
        } else {
          callback();
        }
      }
    };
    var validateUserPhone = (rule, value, callback) => {
      if (this.activeIndex == '2') {
        if (value === "") {
          callback(new Error("请输入手机号码"));
        } else if (!/^[1][3,4,5,7,8,9][0-9]{9}$/.test(value)) {
          callback(new Error("手机号码格式不正确"));
        } else {
          callback();
        }
      }
    };
    // var validateYzm = (rule, value, callback) => {
    //   if (this.activeIndex == '2') {
    //     if (value === "") {
    //       callback(new Error("请输入验证码"));
    //     } else {
    //       callback();
    //     }
    //   }
    // };
    var validatePhoneYzm = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请填写验证码"));
      } else if (value != this.yzm) {
        callback(new Error("验证码不正确"));
      } else {
        callback();
      }
    };
    // username: "jyc",
    //   password: "123456",
    return {
      form: {
        username: "",
        password: "",
        remember: false,
      },
      form2: {
        phone: "",
        yzm: "",
      },
      activeIndex: '1',
      rules: {
        username: [{validator: validateUsername, trigger: "blur"}],
        password: [{validator: validatePassword, trigger: "blur"}],

      },
      rules2: {

        phone: [{validator: validateUserPhone, trigger: "blur"}],
        yzm: [{validator: validatePhoneYzm, trigger: "blur"}],
      },
      flag: false,
      loading: false,
      swicthName: "手机号登录",
      codeBtnWord: '获取验证码', // 获取验证码按钮文字
      waitTime: 61, // 获取验证码按钮失效时间
      yzm:""
    };
  },
  created() {

    let userInfo = getUserInfo();
    // this.form.remember = userInfo?userInfo.remember:false;
    this.form.remember = getUserState();
    if (getUserState()) {
      this.form.username = getSecrecy().username;
      this.form.password = getSecrecy().password;
    }
  },
  computed: {
    // 用于校验手机号码格式是否正确
    phoneNumberStyle() {
      let reg = /^1[3456789]\d{9}$/
      if (!reg.test(this.form2.phone)) {
        return false
      }
      return true
    },
    // 控制获取验证码按钮是否可点击
    getCodeBtnDisable: {
      get() {
        if (this.waitTime == 61) {
          let reg = /^1[3456789]\d{9}$/
          if (reg.test(this.form2.phone)) {
            return false
          }
          return true
        }
        return true
      },
      // 注意：因为计算属性本身没有set方法，不支持在方法中进行修改，而下面我要进行这个操作，所以需要手动添加
      set() {
      }
    },
  },
  methods: {
    getCode() {
      if (this.phoneNumberStyle) {
        let params = {}
        params.receiver = this.form2.phone
        // 调用获取短信验证码接口
        sendPhoneMessageByLogin(params).then(res => {

          if (res.ok) {
            this.yzm = res.data;

            this.$message({
              message: '验证码已发送，请稍候...',
              type: 'success',
              center: true
            })
            // 因为下面用到了定时器，需要保存this指向
            let that = this
            that.waitTime--
            that.getCodeBtnDisable = true
            this.codeBtnWord = `${this.waitTime}s 后重新获取`
            let timer = setInterval(function () {
              if (that.waitTime > 1) {
                that.waitTime--
                that.codeBtnWord = `${that.waitTime}s 后重新获取`
              } else {
                clearInterval(timer)
                that.codeBtnWord = '获取验证码'
                that.getCodeBtnDisable = false
                that.waitTime = 61
              }
            }, 1000)
          }else{
            this.$message.closeAll()
            this.$message({
              dangerouslyUseHTMLString: true,
              type: 'error',
              customClass:'zdy',
              iconClass:' ',
              message: '<div style="font-size: 16px;font-weight: bold;margin-bottom: 20px;margin-top: 10px">&nbsp;&nbsp;&nbsp;&nbsp;该手机号暂未绑定会员账号，请绑定后再试</div><div style="font-size: 14px;font-weight: bold;margin-bottom: 10px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;操作路径:登录会员账号->头像->绑定手机号</div>'
            });
// alert("<div style=\"font-size: 16px;font-weight: bold;margin-bottom: 20px;margin-top: 10px\">该手机号暂未绑定会员账号，请绑定后再试</div><div style=\"font-size: 14px;font-weight: bold;margin-bottom: 10px\">操作路径:登录会员账号->头像->绑定手机号</div>")
          }
        })

      }
    },
    handleSelect(key, keyPath) {
      this.activeIndex = key;
    },
    toHref(url) {

      let newUrl = router.resolve(url);

      window.open(newUrl.href, "_blank");

    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          let form = {};
          if (this.activeIndex == "1") {
            form.username = this.form.username;
            form.password = this.form.password;
            form.rememberMe = this.form.remember;
            form.type = '1';
          } else {
            form.phone = this.form2.phone;
            form.rememberMe = this.form.remember;
            form.type = '2';

          }
          form.browserId = store.state.common.browserId
          login(form).then((res) => {
            if (res.ok) {
              this.$message({
                showClose: true,
                message: "登录成功",
                type: "success",
              });
              sessionStorage.removeItem('version')
              form.remember = this.form.remember;
              // let data = Object.assign(form, res.data);
              if (form.remember) {
                setUserInfo(res.data);
                setUserState(true)
                setSecrecy(form)
              } else {
                restUserInfo(res.data)
                restUserState(true)
                restSecrecy(form)
              }
              this.flag = false;
              if (sessionStorage.getItem('toUrl') != null) {
                this.$router.push({
                  path: sessionStorage.getItem('toUrl')
                })
                sessionStorage.removeItem('toUrl')
              } else {
                window.location.reload()
              }
            }
            this.loading = false;
          });
        }
      });
    },
    close(formName) {
      sessionStorage.removeItem('toUrl')
      this.flag = false;
      this.$refs[formName].resetFields();
      this.swicthName = "手机号登录";
      this.$emit("close");

    },
    show() {
      this.flag = true;
    },
    loginSwitch() {
      this.swicthName =
          this.swicthName == "手机号登录" ? "账号密码登录" : "手机号登录";
      this.form.yzm = ''
      this.$refs.form.resetFields();
    },
  },
};
</script>
<style lang="scss" scoped>
.login-mask {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.5);
  z-index: 999;
}

.login-box {
  position: relative;
  width: 738px;
  background: #ffffff;
  padding-bottom: 40px;
  border-radius: 8px;
  margin: auto;

  .close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 20px;
    cursor: pointer;
  }

  img {
    display: block;
    width: 182px;
    height: 80px;
    margin: 80px auto 60px;
  }

  .login-form {
    width: 360px;
    margin: 0 auto;

    .yzm-btn {
      position: absolute;
      right: 9px;
    }
  }
}

.el-menu-item {
  font-size: 16px;
  font-weight: bold;
}

</style>
<style>
.zdy{
  background-color: #fef0f0;
  border-color: #fde2e2;
  color: #F56C6C;
}
</style>
